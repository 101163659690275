<template>
  <div class="wrap">
    <NavBar
      :title="$t('chat.list.nav')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="null" v-if="!chatList.length">{{ $t("Hint.notMore") }}</div>
    <List
      class="list"
      v-model="loading"
      :finished="!hasMore"
      :finished-text="''"
      :loading-text="$t('Hint.loading')"
      @onload="getList"
      v-else
    >
      <!-- $t('Hint.notMore')没有数据的提示 -->
      <div
        class="list_item"
        v-for="(item, index) in chatList"
        :key="item.id || `list_item_${index}`"
        @click="toRoom(item)"
      >
        <img
          :src="!!item.pictures[0] ? item.pictures[0].url : ''"
          alt=""
          class="avatar"
        />
        <div class="right_bar">
          <div class="top_bar">
            <div class="title">{{ item.content }}</div>
            <Badge :content="badgeGet(item)" v-if="!!badgeGet(item)" />
          </div>
          <div class="content_bar">
            <div class="content">
              <div
                v-html="
                  item.comment_new[0]
                    ? item.comment_new[0].type == 'image'
                      ? `[${$t('chat.list.image')}]`
                      : item.comment_new[0].content
                    : ''
                "
              ></div>
              <!-- {{
                item.comment_new[0]
                  ? item.comment_new[0].type == "image"
                    ? `[${$t("chat.list.image")}]`
                    : item.comment_new[0].content
                  : ""
              }} -->
            </div>
            <div class="time">
              {{
                item.comment_new[0]
                  ? date(item.comment_new[0].indate * 1000)
                  : ""
              }}
            </div>
          </div>
        </div>
      </div>
    </List>
  </div>
</template>

<script>
import { NavBar, List, Badge } from "vant";
import { $get } from "@/utils/request.js";
import verConfig from "../../utils/verConfig";
export default {
  components: { NavBar, List, Badge },
  data() {
    return {
      chatList: [],
      hasMore: true,
      loading: false,
      newsSeen: {},
    };
  },
  created() {
    this.getList();
    const data = localStorage.getItem("seen");
    !!data && (this.newsSeen = JSON.parse(data));
  },
  methods: {
    badgeGet(item) {
      const index = this.newsSeen[item.id];
      let num = 0;
      if (!!index) {
        num = item.comment_new[0].index - index;
      } else {
        num = item.comment;
      }
      if (num > 99) {
        return "99+";
      }
      return num;
    },
    async getList() {
      this.loading = true;
      try {
        const res = await $get("/find/lists", { pagesize: 1000 });
        this.loading = false;
        if (res.data == null) return (this.hasMore = false);
        const { ret, msg, data } = res.data;
        if (ret == 1) {
          const { hasMorePages, lists } = data;
          this.chatList = lists;
          this.hasMore = hasMorePages;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    date(time) {
      if (!time) return "";
      const _time = this.$format(
        time,
        verConfig.dateFormatNoTime || "yy-MM-dd"
      );
      const now = this.$format(
        new Date(),
        verConfig.dateFormatNoTime || "yy-MM-dd"
      );
      if (_time == now) {
        const diff = new Date().getTime() - new Date(time).getTime();
        const _s = 1000;
        const _m = 60 * _s;
        const _h = 60 * _m;
        const _hour = Math.floor(diff / _h);
        const _minute = Math.floor((diff % _h) / _m);
        let str = "";
        if (!!_hour) {
          str = this.$t("chat.list.hour", { h: _hour });
        }
        if (!!_minute) {
          str += "\t" + this.$t("chat.list.minutes", { m: _minute });
        }
        return this.$t("chat.list.ago", { time: str });
      }
      return this.$format(time);
    },
    toRoom(item) {
      this.$router.push(`/ChatRoom?id=${item.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.ellipsis(@line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
}
.null {
  text-align: center;
  padding: calc(20rem / 16) 0;
  color: #999;
}
.list {
  margin: calc(24rem / 16) calc(16rem / 16) calc(16rem / 16);
  .list_item {
    display: flex;
    height: calc(128rem / 16 / 2);
    margin-top: calc(26rem / 16 / 2);
    &:nth-child(1) {
      margin-top: 0;
    }
    .avatar {
      width: calc(110rem / 16 / 2);
      height: calc(110rem / 16 / 2);
      border-radius: 4px;
    }
    .right_bar {
      margin-left: calc(42rem / 16 / 2);
      flex-grow: 1;
      .top_bar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: calc(18rem / 16 / 2);
        .title {
          width: 88%;
          .ellipsis();
          font-size: calc(34rem / 16 / 2);
          // line-height: calc(50rem / 16 / 2);
        }
      }
      .content_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: calc(24rem / 16 / 2) 0;
        border-bottom: 1px solid #dedede;
        .content {
          font-size: calc(34rem / 16 / 2);
          color: #999;
          .ellipsis();
        }
        .time {
          font-size: calc(20rem / 16 / 2);
          color: #b7b7b7;
          margin-left: calc(10rem / 16);
          white-space: nowrap;
        }
      }
    }
  }
}
</style>